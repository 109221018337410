<script setup lang="ts">
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { SUPABASE_DEPENDENCY } from '@wision/supabase';

defineProps({
  title: {
    type: String,
    default: ''
  },
});

const { $supabase } = useNuxtApp();

provide(SUPABASE_DEPENDENCY, $supabase);
</script>

<template>
  <Head>
    <Meta charset="utf-8" />
    <Meta
      name="viewport"
      content="width=device-width"
    />
    <Link
      rel="icon"
      type="image/svg+xml"
      href="/favicon.ico"
    />
    <Title>{{ title }}</title>
  </Head>
  <Body class="h-full bg-black">
    <slot />
  </Body>
</template>
